import { getAttributeOrThrow, qsOptional, qsRequired } from '@/scripts/core/global'
import { type ModalDialog } from '@/scripts/theme/modal-dialog'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { CartModal } from '@/scripts/theme/cart-modal'

export class ModalOpener extends UcoastEl {
	static htmlSelector = 'modal-opener'
	modalSelector: string
	productHandle?: string
	mediaId?: string
	button: HTMLButtonElement
	constructor() {
		super()
		this.modalSelector = getAttributeOrThrow('data-modal', this)
		if (this.modalSelector === 'cart-modal') {
			this.productHandle = getAttributeOrThrow('data-uc-modal-product-handle', this)
			console.log('productHandle', this.productHandle)
		}

		this.button = qsRequired('button', this)
		console.log('button', this.button)
		this.mediaId = this.button.hasAttribute('data-media-id')
			? getAttributeOrThrow('data-media-id', this.button)
			: undefined

		this.button.addEventListener('click', () => {
			if (this.modalSelector === 'cart-modal' && this.productHandle && this.mediaId) {
				const modal = qsOptional<CartModal>(this.modalSelector)
				if (modal) modal.loadAndShow(this.button, this.productHandle, this.mediaId)
			} else {
				const modal = qsOptional<ModalDialog>(this.modalSelector)
				if (modal && this.mediaId) {
                  modal.show(this.button, this.mediaId)
                } else if (modal) {
                  modal.show(this.button)
                }
			}
		})
	}
}
