import { UcoastEl } from '@/scripts/core/UcoastEl'
import {
	closestRequired,
	debounce,
	getAttributeOrThrow,
	ON_CHANGE_DEBOUNCE_TIMER,
	qsaRequired,
	qsRequired,
	removeTrapFocus,
	trapFocus,
} from '@/scripts/core/global'
import { type CartDrawerItems } from '@/scripts/cart/cart-drawer-items'
import { type CartItems } from '@/scripts/cart/cart-items'

export class CartOptionSelect extends UcoastEl {
	static htmlSelector = 'cart-option-select'
	option: 'size' | 'color' | 'qty'
	toggleButton: HTMLButtonElement
	toggleValue: HTMLElement
	panel: HTMLElement
	options: NodeListOf<HTMLButtonElement>
	input: HTMLInputElement
	key: string
	variantId: string
	quantity: string
	cartItems: CartItems | CartDrawerItems
	boundUpdateFunction: () => void
	constructor() {
		super()
		this.option = this.getOption()
		this.key = getAttributeOrThrow('data-uc-cart-option-key', this)
		this.variantId = getAttributeOrThrow('data-uc-cart-option-variant-id', this)
		this.quantity = getAttributeOrThrow('data-uc-cart-option-quantity', this)
		this.input = qsRequired('[data-uc-cart-option-input]', this)
		this.toggleButton = qsRequired('[data-uc-cart-option-toggle-button]', this)
		this.toggleValue = qsRequired('[data-uc-cart-option-toggle-value', this)
		this.panel = qsRequired('[data-uc-cart-option-panel]', this)
		this.options = qsaRequired('[data-uc-cart-option-value]', this)
		this.cartItems = closestRequired<CartDrawerItems>(this, 'cart-drawer-items')

		this.boundUpdateFunction =
			this.option === 'qty'
				? this.changeQuantity.bind(this)
				: this.option === 'color'
				? this.changeColor.bind(this)
				: this.changeSize.bind(this)

		this.input.addEventListener(
			'change',
			debounce((_: Event) => {
				this.boundUpdateFunction()
			}, ON_CHANGE_DEBOUNCE_TIMER)
		)
		this.options.forEach((option) => {
			option.addEventListener('click', (event: Event) => {
				event.preventDefault()
				const value = getAttributeOrThrow('data-uc-cart-option-value', option)
				const name = getAttributeOrThrow('data-uc-cart-option-name', option)
				this.updateValue(value, name)
			})
		})
	}

	updateValue(value: string, name: string) {
		this.input.value = value
		this.toggleValue.innerHTML = name
		this.toggleOff()
		this.boundUpdateFunction()
	}

	override connectedCallback() {
		super.connectedCallback()
		this.toggleButton.addEventListener('click', this.toggle.bind(this))
	}

	changeQuantity(_: Event) {
		this.cartItems.updateQuantity(this.key, this.input.value)
	}
	changeColor(_: Event) {
		this.cartItems.replaceVariant(this.key, this.variantId, this.input.value, this.quantity)
	}
	changeSize(_: Event) {
		this.cartItems.replaceVariant(this.key, this.variantId, this.input.value, this.quantity)
	}
	getOption() {
		const option = getAttributeOrThrow('data-uc-cart-option', this)
		if (option !== 'size' && option !== 'color' && option !== 'qty') {
			throw new Error('could not initialize, option not valid')
		}
		return option
	}
	toggle() {
		if (this.toggleButton.getAttribute('aria-expanded') === 'true') {
			this.toggleOff()
		} else {
			this.toggleOn()
		}
	}
	toggleOn() {
		this.toggleButton.setAttribute('aria-expanded', 'true')
		this.panel.setAttribute('aria-hidden', 'false')
		trapFocus(this.panel, this.toggleButton)
	}
	toggleOff() {
		this.toggleButton.setAttribute('aria-expanded', 'false')
		this.panel.setAttribute('aria-hidden', 'true')
		removeTrapFocus(this.toggleButton)
	}
}
