import { DetailsDisclosure } from '@/scripts/theme/details-disclosure'
import {
	getAttributeOrThrow, mediaLoader,
	qsaOptional,
	qsOptional,
	qsRequired,
	replaceSrcSet,
	scaleValue,
	targetClosestRequired,
	throttle,
} from '@/scripts/core/global'
import { type StickyHeader } from '@/scripts/theme/sticky-header'
import { SELECTORS } from '@/scripts/core/global'

export class HeaderMenu extends DetailsDisclosure {
	static override htmlSelector = 'header-menu'
	header: StickyHeader | null
	megaMenuToggles?: NodeListOf<HTMLElement>
	megaMenuContentWrappers?: NodeListOf<HTMLElement>
	megaMenuHoverTriggers?: NodeListOf<HTMLElement>

	constructor() {
		super()
		this.header = qsRequired<StickyHeader>(SELECTORS.headerWrapper)
		this.megaMenuToggles = qsaOptional('[data-uc-megamenu-toggle]', this)
		this.megaMenuContentWrappers = qsaOptional('[data-uc-megamenu-content]', this)

		this.megaMenuToggles?.forEach((toggle) => {
			const handle = getAttributeOrThrow('data-uc-megamenu-toggle', toggle)
			const content = qsRequired(`[data-uc-megamenu-content="${handle}"]`, this)
			const defaultMegaMenuMedia = qsOptional<HTMLImageElement>('[data-uc-mega-menu-media="default"]', content)
			if (!defaultMegaMenuMedia) return
			const defaultMediaType =
				defaultMegaMenuMedia.getAttribute('data-uc-mega-menu-media-type') == 'image' ? 'image' : 'video'
			const debouncedClick = throttle((event: Event) => {
				const details = targetClosestRequired(event, 'details')
				if (toggle.getAttribute('aria-expanded') === 'true') {
					window.setTimeout(() => {
						details.removeAttribute('open')
					}, 500)
					toggle.setAttribute('aria-expanded', 'false')
				} else {
					details.setAttribute('open', '')
					toggle.setAttribute('aria-expanded', 'true')
				}
			}, 501)
			toggle.addEventListener('click', (event) => {
				event.preventDefault()
				debouncedClick(event)
			})
			toggle.addEventListener('mouseenter', () => {
				if (defaultMediaType == 'image') {
					const image = qsRequired<HTMLImageElement>(
						`[data-uc-megamenu-content="${handle}"] [data-uc-mega-menu-media="default"] img`,
						this
					)
					replaceSrcSet(image, {
						overrideTargetSize: window.innerWidth,
						loadImmediately: true,
					})
				} else {
					// const selectorToPreload = `[data-uc-megamenu-content="${handle}"] [data-uc-mega-menu-media="default"] ucoast-video`
					// TODO: video preload
				}
			})
		})
		this.megaMenuHoverTriggers = qsaOptional('[data-uc-megamenu-hover-trigger]', this)
		this.megaMenuHoverTriggers?.forEach((trigger) => {
			const handle = getAttributeOrThrow('data-uc-megamenu-hover-trigger', trigger)
			if (handle === '') return
			trigger.addEventListener('mouseover', (_: Event) => {
				this.onHover(handle)
			})
		})
	}

	onHover(handle: string) {
		const media = qsOptional(`[data-uc-mega-menu-media="${handle}"]`)
		if (!media) return
		const activeMedia = qsRequired(`.active[data-uc-mega-menu-media]`)
		if (activeMedia === media) return
		activeMedia.classList.remove('active')
		media.classList.add('active')
		mediaLoader()
	}

	override onToggle() {
		if (!this.header) return
		this.header.preventHide = this.mainDetailsToggle.open

		if (document.documentElement.style.getPropertyValue('--header-bottom-position-desktop') !== '') return

		const viewport = window.innerWidth >= 750 ? 'desktop' : 'mobile'
		document.documentElement.style.setProperty(
			'--header-bottom-position-desktop',
			`calc(${scaleValue(this.header.getBoundingClientRect().bottom, viewport)} * var(--ax))`
		)
	}
}
