import {getAttributeOrThrow, qsOptional, qsRequired} from '@/scripts/core/global';
import { UcoastEl } from '@/scripts/core/UcoastEl';
import { mediaLoader } from '@/scripts/core/global';
import {CartDrawer} from "@/scripts/cart/cart-drawer";

export class CartRecommendations extends UcoastEl {
    static htmlSelector = 'cart-recommendations';
    toggleButton: HTMLButtonElement
    cartDrawer: CartDrawer
    constructor() {
        super();
        this.toggleButton = qsRequired('[data-uc-cart-recommendations-toggle]')
        this.cartDrawer = qsRequired('cart-drawer')
    }

    override connectedCallback() {
        this.toggleButton.addEventListener('click', (_: Event) => {
            const ariaExpanded = this.toggleButton.getAttribute('aria-expanded')
            const slider = qsRequired('slider-component', this)
            if (ariaExpanded === 'true') {
                this.toggleButton.setAttribute('aria-expanded', 'false')
                slider.style.maxHeight = '0px';
                window.setTimeout(() => {
                    qsRequired('html').classList.remove('cart-recommendations-open')
                }, 301)
            } else {
                qsRequired('html').classList.add('cart-recommendations-open')
                this.toggleButton.setAttribute('aria-expanded', 'true')
                slider.style.maxHeight = `${slider.scrollHeight}px`;
            }
        })
        const handleIntersection = (entries:IntersectionObserverEntry[], observer:IntersectionObserver) => {
            if (!entries[0].isIntersecting) return;
            observer.unobserve(this);

            const fetchUrl = getAttributeOrThrow('data-url', this);

            fetch(fetchUrl)
                .then((response) => response.text())
                .then((text) => {
                    const html = document.createElement('div');
                    html.innerHTML = text;
                    const recommendations = qsOptional<CartRecommendations>('cart-recommendations', html)

                    if (recommendations && recommendations.innerHTML.trim().length) {
                        this.innerHTML = recommendations.innerHTML;
                        mediaLoader()
                    }

                    if (!this.querySelector('slideshow-component') && this.classList.contains('complementary-products')) {
                        this.remove();
                    }

                    if (html.querySelector('.grid__item')) {
                        this.classList.add('cart-recommendations--loaded');
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        };

        new IntersectionObserver(handleIntersection.bind(this), { rootMargin: '0px 0px 400px 0px' }).observe(this);
    }
}
