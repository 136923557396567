import {getAttributeOrThrow} from '@/scripts/core/global'
import { type SubscriberCallback } from '@/scripts/core/global'
import { qsRequired } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl';

export class QuantityInput extends UcoastEl {
	static htmlSelector = 'quantity-input'
	input: HTMLInputElement
	changeEvent: Event
	key: string
	quantityUpdateUnsubscriber: SubscriberCallback | undefined = undefined
	constructor() {
		super()
		this.input = qsRequired('input', this)
		this.key = getAttributeOrThrow('data-uc-cart-quantity-key', this.input)
		this.changeEvent = new Event('change', { bubbles: true })
	}

	override disconnectedCallback() {
		if (this.quantityUpdateUnsubscriber) {
			this.quantityUpdateUnsubscriber(undefined)
		}
	}

	update(event: Event, newValue: string) {
		event.preventDefault()
		if (newValue === this.input.value) return
		this.input.value = newValue
		this.input.dispatchEvent(this.changeEvent)
	}
}
