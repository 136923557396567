import { CartItems } from '@/scripts/cart/cart-items'
import { SELECTORS } from '@/scripts/core/global';

export class CartDrawerItems extends CartItems {
	static override htmlSelector = 'cart-drawer-items'
	static override selectors = {
		...CartItems.selectors,
		element: 'cart-drawer-items',
		lineItemStatus: '[data-uc-cart-drawer-status]',
		errors: '[data-uc-cart-drawer-errors]',
		liveRegionText: '[data-uc-cart-drawer-live-region-text]',
		main: '[data-uc-cart-drawer-main]',
		lineQuantity: '#CartDrawer-Quantity',
	}
	constructor() {
		super();

	}
	override setInstanceSelectors() {
		this.instanceSelectors = CartDrawerItems.selectors
	}
	override getSectionsToRender() {
		return [
			{
				id: 'CartDrawer',
				section: 'cart-drawer',
				selector: this.instanceSelectors.cartDrawerInner,
			},
			{
				id: 'CartIconBubble',
				section: 'cart-icon-bubble',
				selector: SELECTORS.cartLink,
			},
		]
	}
}
